.descriptionbar-container {
    position: relative;
    background: white;
    padding: 1em;
    -webkit-box-shadow: -3px 9px 10px -1px rgba(224,224,224,1);
    -moz-box-shadow: -3px 9px 10px -1px rgba(224,224,224,1);
    box-shadow: -3px 9px 10px -1px rgba(224,224,224,1);
    border-radius: 10px;
    margin-top: -2em;
    z-index: 0;
    margin-left: 5em;
    margin-right: 5em;

    .descriptionbar-item {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 10em;
        text-align: center;
        transition: all .5s ease-in-out;

        &:not(:first-child) {

            @media screen and (min-width: 768px) {
                border-left: 1px solid gray;                
            }
        }

        &.red {

            &:hover {
                background-color: #c71a1a;
                color: #fff;
            }
           
        }

        &.green {

            &:hover {
                background-color: #58d664;
                color: #fff;
            }
            
        }

        &.blue {

            &:hover {
                background-color: #5cb1f6;
                color: #000;
            }
          
        }

        &.purple {

            &:hover {
                background-color: #a243e6;
                color: #fff;
            }
          
        }
    }
}