/*********************************************************************************

	Template Name: Trydo React Degital Agency Template
	Note: This is style css.

**********************************************************************************/

/**************************************************************
	
	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|
	|	|___ variables
	|	|___ mixins
	|	|___ reset
	|	|___ typography
	|	|___ extend
	|	|___ animations
	|	|___ shortcode
	|	|___ spacing
	|
	|
	|___Header Styles
	|	|___ header
	|	|___ mainmenu
	|
	|
	|___Inner Styles
	|	|___ breadcrump 
	|	|___ section-title 
	|	|___ button
	|	|___ pagination
	|	|___ socialicon
	|	|___ footer
	|
	|___Elements Styles
	|	|___ portfolio 
	|	|___ service 
	|	|___ slider 
	|	|___ counterup 
	|	|___ testimonial 
	|	|___ brand 
	|	|___ team 
	|	|___ callto-action 
	|	|___ tab 
	|	|___ accordion 
	|	|___ list 
	|	|___ contact 
	|
	|___BLog Styles
	|	|___ blog 
	|	|___ blog-details 
	|
	|___Template Styles
	|	|___ about 
	|	|___ portfolio-details
	|	|___ service-details
	|	|___ preview
	|
	|
	|___ END STYLESHEET INDEXING

***************************************************************/

/**************************************
    Default Styles
***************************************/
@import "default/variables";
@import "default/reset";
@import "default/typography";
@import "default/helper";
@import "default/mixins";
@import "default/animations";
@import "default/buttons";

/**************************************
    Inner Styles
***************************************/
@import "common/common";

/**************************************
    Elements Styles
***************************************/
@import "elements/header";
@import "elements/mobileMenu";
@import "elements/hero";
@import "elements/about";
@import "elements/blog";
@import "elements/contact";
@import "elements/contentBlock";
@import "elements/cta";
@import "elements/faq";
@import "elements/features";
@import "elements/footer";
@import "elements/funFact";
@import "elements/price";
@import "elements/projects";
@import "elements/section";
@import "elements/services";
@import "elements/team";
@import "elements/testimonial";
@import "elements/descriptionbar";
@import "elements/chooseus";
@import "elements/dropdown-button";

.CookieConsent {
    z-index: 999999999 !important;
}

.age-verification-dialog {
	background-color: #ff4646 !important;
	color: white;
	z-index: 999999999 !important;


	.swal2-confirm {
		background-color: white !important;
		color: #ff4646 !important;
	}
}

.modal-open {
	overflow: hidden !important;
}


.w-100 {
	width: 100% !important;
}