.dropdown-item {
    margin-top: 0 !important;
}

.show > .btn-primary.dropdown-toggle {
    background-color: black !important;
    border-color: black !important;
}

.btn-primary:focus {
    background-color: black !important;
    border-color: black !important;
    box-shadow: none;
}

.dropdown-theme {
    margin: 0 !important;
    width: 100% !important;
}

.dropdown-button {
    width: 100%;
}

.dropdown-button__options-container {
    background-color: #febc35;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 9999999;
    width: 250px;
    transition: all ease-in-out 100ms;
    .not-visible {
        display: none;
    }
 

    .dropdown-button__option {
        margin-top: 0 !important;
        text-align: center;
        font-weight: bold;
        padding: 12px;
        margin-right: 0 !important;


        &:hover {
            background-color: black;
            color: #febc35;
        }
    }
}